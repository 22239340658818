.foldButton {
  position: absolute;
  right: -25px;
  top: 5px;
  width: 25px;
  height: 50px;
  border-radius: 0 0.4rem 0.4rem 0;
  background: var(--color-brand);
}

._folded svg {
  transform: rotateZ(180deg);
}

.content {
  height: 100%;
  background: linear-gradient(0deg, var(--color-foreground) 0%, var(--sidebar-background) 84%);
  color: var(--color-brand);
}

header {
  padding: 1rem;
}

section {
  border-top: 1px solid var(--sidebar-brand-thin);
  padding: 2rem 1.5rem 1rem;
}

h1 {
  font-size: var(--font-size-M);
  text-transform: uppercase;
  color: var(--sidebar-brand-transparent);
  font-weight: normal;
}

ul {
  margin: 0;
  list-style-type: none;
}

li {
  display: block;
  margin: 1rem 0;
  font-size: var(--font-size-L);
}

.toggle {
  pointer-events: none;
  display: inline-block;
  border-radius: 99px;
  width: 24px;
  height: 1rem;
  margin-right: 0.5rem;
}

.toggle.loading {
  visibility: hidden;
  margin-right: -4px;
}

.toggle.loading::after {
  visibility: visible;
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: inherit;
  animation: spin 0.5s ease-in-out infinite;
  background-color: none !important;
  margin-left: -10px;
  vertical-align: top;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.details {
  display: flex;
  flex-wrap: wrap;
}

.property {
  margin: 1rem 2rem 0 0;
}

.property span[role='img'] {
  line-height: 0;
}

.property span[role='img']::after {
  content: attr(aria-label);
}

.property label {
  font-size: var(--font-size-S);
}

.info {
  fill: var(--color-brand-secondary);
  cursor: help;
  margin-left: 0.5rem;
  transform: scale(0.7);
  vertical-align: bottom;
}
