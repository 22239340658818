@import url(https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap);
:root {
  /* SIZES */
  --header-height: 6rem;
  --block-height-M: 4rem;
  --block-height-S: 3.2rem;
  --space-S: 1rem;
  --space-M: 2rem;
  --space-L: 4rem;

  /* TYPE */
  --font-family: 'Roboto', sans-serif;
  --font-weight-regular: 400;
  --font-size-XS: 1rem;
  --font-size-S: 1.2rem;
  --font-size-M: 1.4rem;
  --font-size-L: 1.6rem;
  --font-size-XL: 2rem;
  --font-line-height-L: 1.8rem;
  --font-line-height-XL: 3.4rem;
  --font-weight-regular: 400;
  --font-weight-light: 300;

  /* COLORS */
  --color-brand: rgba(22, 63, 137, 1);
  --color-brand-secondary: rgba(22, 63, 137, 0.7);
  --color-brand-highlight: #d1dee7;
  --color-background-rgb: 10, 23, 56;
  --color-background: rgb(var(--color-background-rgb));
  --color-background-transparent: rgba(var(--color-background-rgb), 0.5);
  --color-foreground: white;
  --color-foreground-transparent: rgba(255, 255, 255, 0.5);
  --sidebar-background: #e5f0f2;
  --sidebar-brand-transparent: rgba(22, 63, 137, 0.7);
  --sidebar-brand-thin: rgba(22, 63, 137, 0.1);

  /* SHADOWS */
  --block-shadow: 0 0 1rem 0 rgba(44, 61, 108, 0.15);
  --drop-shadow: drop-shadow(0 0.5rem 1rem rgba(44, 61, 108, 0.15));
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  font-family: var(--font-family);
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  font-size: 1.6rem;
  font-size: var(--font-size-L);
  color: rgba(22, 63, 137, 1);
  color: var(--color-brand);
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

button {
  font-family: 'Roboto', sans-serif;
  font-family: var(--font-family);
  font-size: 1.2rem;
  font-size: var(--font-size-S);
  line-height: 2rem;
  color: white;
  color: var(--color-foreground);
  fill: white;
  fill: var(--color-foreground);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 150ms ease-out;
  background-repeat: no-repeat;
  background-position: center center;
}

label {
  color: rgba(22, 63, 137, 0.7);
  color: var(--color-brand-secondary);
  font-size: 1.2rem;
  font-size: var(--font-size-S);
  line-height: 2rem;
  text-transform: uppercase;
  display: block;
}

svg[aria-label],
span[aria-label],
button[aria-label] {
  position: relative;
}

svg[aria-label]::before,
span[aria-label]::before,
button[aria-label]::before {
  content: attr(aria-label);
  display: block;
  position: absolute;
  left: 50%;
  bottom: calc(100% + 1rem);
  width: -webkit-max-content;
  width: max-content;
  min-height: 2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  font-size: 1.4rem;
  font-size: var(--font-size-M);
  line-height: 1.5;
  border-radius: 3px;
  background: rgba(22, 63, 137, 1);
  background: var(--color-brand);
  color: white;
  color: var(--color-foreground);
  -webkit-filter: drop-shadow(0 0.5rem 1rem rgba(44, 61, 108, 0.15));
          filter: drop-shadow(0 0.5rem 1rem rgba(44, 61, 108, 0.15));
  -webkit-filter: var(--drop-shadow);
          filter: var(--drop-shadow);
  border: 1px solid var(--color-background-highlight);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 2;
  pointer-events: none;
  -webkit-transform: translate(-50%, 1rem);
          transform: translate(-50%, 1rem);
}

svg[aria-label]:hover::before,
span[aria-label]:hover::before,
button[aria-label]:hover::before {
  opacity: 1;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  transition: all 0.2s ease-in-out 0.5s;
}

svg[data-tip-wrap='multiline']::before,
span[data-tip-wrap='multiline']::before,
button[data-tip-wrap='multiline']::before {
  width: 26rem;
  white-space: unset;
  text-align: left;
}

.Loader_loader__1QdfB {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background-transparent);
  pointer-events: none;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader_spinner__1xZFU {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: inherit;
  -webkit-animation: Loader_spin__10KGJ 1s ease-in-out infinite;
          animation: Loader_spin__10KGJ 1s ease-in-out infinite;
}

@-webkit-keyframes Loader_spin__10KGJ {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Loader_spin__10KGJ {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Timebar_graphSelector__3rUVv {
  position: absolute;
  right: 0.5rem;
  bottom: 1.6rem;
  z-index: 100;
}

.Timebar_graphSelector__3rUVv::after {
  content: ' ';
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 1.9rem;
  pointer-events: none;
  background: var(--color-brand) url(/track-inspector/static/media/settings.2953d5bb.svg) no-repeat center center;
}

.Timebar_graphSelectorSelect__2hJzu {
  -webkit-appearance: none;
  appearance: none;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background: var(--color-brand);
  font-size: 0;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
}

.Timebar_graphSelectorSelect__2hJzu:hover {
  font-size: var(--font-size-M);
  padding: 0 4rem 0 1rem;
  width: auto;
  width: initial;
}

.Timebar_graphSelectorSelect__2hJzu:focus {
  outline-width: 0;
}

.Timebar_noEncounters__3S0B- {
  position: absolute;
  width: 300px;
  margin-left: -150px;
  left: 50%;
  top: 35%;
}

.Sidebar_foldButton__1603b {
  position: absolute;
  right: -25px;
  top: 5px;
  width: 25px;
  height: 50px;
  border-radius: 0 0.4rem 0.4rem 0;
  background: var(--color-brand);
}

.Sidebar__folded__3ottS svg {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

.Sidebar_content__QlHUG {
  height: 100%;
  background: linear-gradient(0deg, var(--color-foreground) 0%, var(--sidebar-background) 84%);
  color: var(--color-brand);
}

header {
  padding: 1rem;
}

section {
  border-top: 1px solid var(--sidebar-brand-thin);
  padding: 2rem 1.5rem 1rem;
}

h1 {
  font-size: var(--font-size-M);
  text-transform: uppercase;
  color: var(--sidebar-brand-transparent);
  font-weight: normal;
}

ul {
  margin: 0;
  list-style-type: none;
}

li {
  display: block;
  margin: 1rem 0;
  font-size: var(--font-size-L);
}

.Sidebar_toggle__1pUMv {
  pointer-events: none;
  display: inline-block;
  border-radius: 99px;
  width: 24px;
  height: 1rem;
  margin-right: 0.5rem;
}

.Sidebar_toggle__1pUMv.Sidebar_loading__675i6 {
  visibility: hidden;
  margin-right: -4px;
}

.Sidebar_toggle__1pUMv.Sidebar_loading__675i6::after {
  visibility: visible;
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: inherit;
  -webkit-animation: Sidebar_spin__ITBdq 0.5s ease-in-out infinite;
          animation: Sidebar_spin__ITBdq 0.5s ease-in-out infinite;
  background-color: none !important;
  margin-left: -10px;
  vertical-align: top;
}

@-webkit-keyframes Sidebar_spin__ITBdq {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Sidebar_spin__ITBdq {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Sidebar_details__m6e3S {
  display: flex;
  flex-wrap: wrap;
}

.Sidebar_property__24rFY {
  margin: 1rem 2rem 0 0;
}

.Sidebar_property__24rFY span[role='img'] {
  line-height: 0;
}

.Sidebar_property__24rFY span[role='img']::after {
  content: attr(aria-label);
}

.Sidebar_property__24rFY label {
  font-size: var(--font-size-S);
}

.Sidebar_info__3a3dx {
  fill: var(--color-brand-secondary);
  cursor: help;
  margin-left: 0.5rem;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  vertical-align: bottom;
}

body {
  overflow: hidden;
  font-size: 1.6rem;
}

.App_app__rUB5l {
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'sidebar map' 'sidebar timebar';
  background: var(--color-background);
  color: var(--color-foreground);
}

.App_sidebar__gjs7y {
  grid-area: sidebar;
  position: relative;
  z-index: 100;
  width: 32rem;
  transition: width 0.15s;
}

.App__noSidebar__1_4at {
  width: 0;
}

.App_map__1R6xj {
  grid-area: map;
  position: relative;
}

.App_timebar__3QxoQ {
  grid-area: timebar;
}

.App_mobileContainer__1fWyy {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App_mobileLogo__30Zqf {
  position: absolute;
  left: 0;
  top: 0;
  padding: var(--space-M);
}

.App_mobileImage__M6oOV {
  width: 100%;
  max-width: 412px;
}

.App_mobileDisclaimer__17LEz {
  font-size: var(--font-size-L);
  text-transform: none;
  text-transform: initial;
  text-align: center;
  max-width: 80%;
  line-height: 2.2rem;
}

