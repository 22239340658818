.graphSelector {
  position: absolute;
  right: 0.5rem;
  bottom: 1.6rem;
  z-index: 100;
}

.graphSelector::after {
  content: ' ';
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 1.9rem;
  pointer-events: none;
  background: var(--color-brand) url('../../assets/icons/settings.svg') no-repeat center center;
}

.graphSelectorSelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background: var(--color-brand);
  font-size: 0;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
}

.graphSelectorSelect:hover {
  font-size: var(--font-size-M);
  padding: 0 4rem 0 1rem;
  width: initial;
}

.graphSelectorSelect:focus {
  outline-width: 0;
}

.noEncounters {
  position: absolute;
  width: 300px;
  margin-left: -150px;
  left: 50%;
  top: 35%;
}
