.loader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background-transparent);
  pointer-events: none;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: inherit;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
