body {
  overflow: hidden;
  font-size: 1.6rem;
}

.app {
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'sidebar map' 'sidebar timebar';
  background: var(--color-background);
  color: var(--color-foreground);
}

.sidebar {
  grid-area: sidebar;
  position: relative;
  z-index: 100;
  width: 32rem;
  transition: width 0.15s;
}

._noSidebar {
  width: 0;
}

.map {
  grid-area: map;
  position: relative;
}

.timebar {
  grid-area: timebar;
}

.mobileContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobileLogo {
  position: absolute;
  left: 0;
  top: 0;
  padding: var(--space-M);
}

.mobileImage {
  width: 100%;
  max-width: 412px;
}

.mobileDisclaimer {
  font-size: var(--font-size-L);
  text-transform: initial;
  text-align: center;
  max-width: 80%;
  line-height: 2.2rem;
}
